<template>
    <div>
        <el-row style="text-align: right;">
            <el-button type="primary" size="medium" v-if="edit" v-permission="'waiter:student:access'"
                       @click="addAccess">新增
            </el-button>
            <el-button type="success" size="medium" v-if="edit" v-permission="'waiter:access:upload'" @click="upload">
                导入
            </el-button>
        </el-row>
        <el-table :data="dataList" stripe fit max-height="640">
            <el-table-column align="center" label="回访时间" prop="accessTime"></el-table-column>
            <el-table-column align="center" label="回访类型" prop="type">
                <template v-slot="scope">
                    <span v-for="item in accessTypeList" :key="item.id"
                          v-if="scope.row.type === item.dictValue">{{ item.dictLabel }}</span>
                </template>
            </el-table-column>
            <el-table-column align="center" label="详情" prop="remarks"></el-table-column>
            <el-table-column align="center" label="回访人" prop="accountName"></el-table-column>
            <!--            <el-table-column align="center" label="操作" fixed="right">-->
            <!--                <template v-slot="scope">-->
            <!--                    <el-button type="text" size="mini" icon="el-icon-user" v-if="edit" @click="info(scope.row)">详情-->
            <!--                    </el-button>-->
            <!--                    <el-button size="mini" type="text" @click="handleEdit(scope.row)">编辑</el-button>-->
            <!--                </template>-->
            <!--            </el-table-column>-->
        </el-table>

        <el-pagination
                @size-change="findPage"
                @current-change="findPage"
                :current-page="params.current"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="params.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>

        <WaiterAccessDetail v-if="accessRecord.visible && servicesId" :student-id="studentId" :services-id="servicesId"
                            :detail="accessRecord" :callback="findPage"></WaiterAccessDetail>

        <upload-access :callback="findPage" :access-pro="accessPro"></upload-access>
    </div>
</template>

<script>
import * as accessApi from "@/api/waiter/waiterAccessRecord"
import WaiterAccessDetail from "@/views/waiter/detail/WaiterAccessDetail"
import * as dictionaryApi from "@/api/system/dictionary";
import * as serviceApi from "@/api/waiter/waiterServices";
import UploadAccess from "@/views/waiter/detail/UploadAccess.vue";

export default {
    name: "WaiterStudentAccessRecord",
    components: {
        UploadAccess,
        WaiterAccessDetail
    },
    data() {
        return {
            accessPro: {
                show: false,
            },
            accessRecord: {
                visible: false
            },
            dataList: [],
            params: {
                current: 1,
                size: 10
            },
            total: 0,
            accessTypeList: [],
            serviceList: [],
        }
    },
    props: {
        studentId: {
            type: Number,
            require: true
        },
        servicesId: {
            type: Number,
            require: false,
            default: 0
        },
        edit: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.params.studentId = this.studentId
        this.findPage()
        this.findAccessType()
        this.findService()
    },
    methods: {
        upload() {
            this.accessPro.show = true
        },
        findService() {
            serviceApi.findList().then(res => {
                if (res.success) {
                    this.serviceList = res.data
                }
            })
        },
        findAccessType() {
            dictionaryApi.findChildrenByCode('accessType').then(res => {
                if (res.success) {
                    this.accessTypeList = res.data
                }
            })
        },
        addAccess() {
            this.accessRecord.visible = true

        },
        info(row) {

        },
        handleEdit(row) {

        },
        findPage() {
            accessApi.findPage(this.params).then(res => {
                if (res.success) {
                    this.dataList = res.data.records
                    this.total = res.data.total
                }
            })
        },
    }
}
</script>

<style scoped>
.el-pagination {
    bottom: 10px;
    position: fixed;
}
</style>